import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { Suspense, lazy, useEffect } from "react";
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { COLOR } from "./app-dt-sell/common/constant";
import Loader from "./app-dt-sell/components/loader";
import store from './app-dt-sell/store';
import './App.css';

const Login = lazy(() => import("./app-dt-sell/auth/login"));
const Dashboard = lazy(() => import("./app-dt-sell/dashboard"));
const Company = lazy(() => import("./app-dt-sell/company"));
const Branch = lazy(() => import("./app-dt-sell/branch-details/branch"));
const Party = lazy(() => import("./app-dt-sell/party-details/party"));
const PartyDetails = lazy(() => import("./app-dt-sell/party-details/party-details"));
const Employee = lazy(() => import("./app-dt-sell/employee"));
const Inventory = lazy(() => import("./app-dt-sell/inventory"));
const Memo = lazy(() => import("./app-dt-sell/memo-view"));
const Sale = lazy(() => import("./app-dt-sell/sale-view"));
const LabSend = lazy(() => import("./app-dt-sell/lab-send"));
const FormPermission = lazy(() => import("./app-dt-sell/form-permission"));
const SpecialCut = lazy(() => import("./app-dt-sell/special-cut"));
const Layout = lazy(() => import("./app-dt-sell/auth/layout"));
const NotFound = lazy(() => import("./app-dt-sell/components/NotFound"));

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR.primary
    }
  }
});

function App() {

  return (
    <React.Fragment>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<AuthRoute><Login /></AuthRoute>} />
                {/* Private Routes */}
                <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
                  <Route path="/company" element={<Layout><Company type_id={0} form_name="Company" /></Layout>} />
                  <Route path="/branch" element={<Layout><Branch type_id={0} form_name="Branch" /></Layout>} />
                  <Route path="/party" element={<Layout><Party type_id={0} form_name="Party" /></Layout>} />
                  <Route path="/party/details" element={<Layout><PartyDetails type_id={0} form_name="Party" /></Layout>} />
                  <Route path="/employee" element={<Layout><Employee type_id={0} form_name="Employee" /></Layout>} />
                  <Route path="/Inventory" element={<Layout><Inventory type_id={0} form_name="Inventory" /></Layout>} />
                  <Route path="/memo" element={<Layout><Memo type_id={0} form_name="Memo" /></Layout>} />
                  <Route path="/sale" element={<Layout><Sale type_id={0} form_name="Sale" /></Layout>} />
                  <Route path="/lab_send" element={<Layout><LabSend type_id={0} form_name="lab_send" /></Layout>} />
                  <Route path="/form_permission" element={<Layout><FormPermission type_id={0} form_name="form_permission" /></Layout>} />
                </Route>
                <Route path="/special-cut" element={<SpecialCut type_id={0} form_name="special-cut" />} />

                {/* Catch-All Route */}
                <Route path="*" element={<PrivateRoute><NotFound /></PrivateRoute>} />
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </Suspense>
    </React.Fragment>
  );
}

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("userAuth");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token, navigate]);

  if (!token) {
    return null;
  }

  return children ? children : <Outlet />;
};

const AuthRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("userAuth");

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, [token, navigate]);

  if (token) {
    return null;
  }

  return children;
};

export default App;
