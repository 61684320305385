import React from "react";

const Loader = (props) => {
    return (
        <div className="loader">
            <div className="spinner" />
        </div>
    )
};

export default Loader;
